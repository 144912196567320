// Block editor styles
// Block: "core/quote"

@mixin block-core-quote {
	blockquote {
		margin-left: 0;
		margin-right: 0;
		line-height: 1.2;
		@include pwd-fontsize($base-font-size + 2);
	}

	blockquote:not(.is-large):not(.is-style-large),
	.post-single__excerpt {
		padding: 0 0 0 $grid-gutter-width * 0.5;
		border-left: 4px solid #000000;
	}

	blockquote.is-style-large,
	blockquote.is-large,
	.post-single__excerpt {
		font-style: italic;
	}

	blockquote {
		.wp-block-quote__citation,
		cite,
		footer {
			position: relative;
			margin-top: 1em;
			color: lighten($base-text-color, 20%);
			font-style: normal;
			@include pwd-fontsize($base-font-size - 2);
		}

		&.is-style-large,
		&.is-large {
			padding: 0 1em;

			p {
				@include pwd-fontsize($base-font-size + 4);
			}

			cite,
			footer {
				@include pwd-fontsize();
			}
		}
	}

	@media screen {
		blockquote {
			&:not(.is-large):not(.is-style-large) {
				border-left-color: $theme-color-4;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		blockquote:not(.is-large):not(.is-style-large) {
			padding-left: 25px;
		}
	}
}
