// Block editor styles
// Block: "core/paragraph"

@mixin block-core-paragraph {
	.has-drop-cap:not(:focus)::first-letter {
		margin-right: .1em;
		font-size: 3em;
		font-weight: bold;
		font-style: normal;
		line-height: 1em;
		text-transform: uppercase;
	}
}
