// Block editor styles
// Block: "core/separator"

@mixin block-core-separator {
	hr {
		box-sizing: content-box;
		margin-left: auto;
		margin-right: auto;
		height: 1px;
		color: $base-ruler-color;
		border: 0;
		border-bottom: 2px solid $base-ruler-color;

		margin-top: 5rem;
		margin-bottom: 5rem;

		&:not(.is-style-wide):not(.is-style-dots) {
			max-width: 100px;
		}

		&.is-style-wide {
			border-bottom-width: 1px;
		}

		&.is-style-dots {
			height: auto;
			max-width: none;
			text-align: center;
			line-height: 1;
			background: none;
			border: 0;

			&::before {
				padding-left: 2em;
				color: $base-ruler-color;
				font-family: serif;
				font-size: 20px;
				letter-spacing: 2em;
				content: "\00b7 \00b7 \00b7";
			}
		}

		&.theme-color-2 {
			border-color: $theme-color-2;
			color: $theme-color-2;
		}
	}
}
