// Block editor styles
// Block: "core/latest-posts"

@use "sass:math";

@mixin block-core-latest-posts {
	.wp-block-latest-posts {
		list-style: none;
		margin-left: 0;
		padding-left: 0;

		&.alignleft {
			margin-right: 2em;
		}

		&.alignright {
			margin-left: 2em;
		}

		&.is-grid {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;
			margin-left: $grid-gutter-width * 0.5 * -1;
			margin-right: $grid-gutter-width * 0.5 * -1;
			padding: 0;
			list-style: none;

			li {
				width: 100%;
				margin: 0 0 $grid-gutter-width 0;
				padding-left: $grid-gutter-width * 0.5;
				padding-right: $grid-gutter-width * 0.5;
			}
		}

		&.has-dates:not(.is-grid) li {
			display: flex;
			flex-direction: column-reverse;
			border-bottom: 1px solid $theme-color-2;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		a {
			font-weight: 400;
			text-decoration: none;
		}
	}

	.wp-block-latest-posts__post-date {
		display: block;
		margin: 0;
		line-height: math.div(24, 18);
		@include pwd-fontsize(18);
	}

	@media screen {
		.wp-block-latest-posts__post-date {
			color: $theme-color-2;
		}
	}

	@media only print, screen and (min-width: map-get($grid-breakpoints, sm)) {
		.wp-block-latest-posts.is-grid li {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	@include media-breakpoint-up(lg) {
		.wp-block-latest-posts {
			&.columns-3 li,
			&.columns-4 li,
			&.columns-5 li,
			&.columns-6 li {
				flex: 0 0 percentage(math.div(1, 3));
				max-width: percentage(math.div(1, 3));
			}
		}
	}
}
