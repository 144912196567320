// Block editor styles
// Block: "core/video"

@mixin block-core-video() {
	.wp-block-video.aligncenter {
		text-align: center;
	}

	@supports ((position: -webkit-sticky) or (position: sticky)) {
		.wp-block-video [poster] {
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
}
