// Content styles / captions

@mixin content-caption() {
	figcaption {
		margin-top: .6em;
		font-style: italic;
		text-align: center;
		line-height: 1.4;
		color: lighten($base-text-color, 20%);
		@include pwd-fontsize(18);
	}
}
