// Block editor styles
// Block: "core/file"

@mixin block-core-file {
	.wp-block-file a {
		margin-right: 10px;
	}

	.wp-block-file__button {
		display: inline-block;
		padding: 6px 10px;
		color: $base-text-color;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		border: 1px solid $base-text-color;
		border-radius: 2px;
		outline-color: #000000;
		@include pwd-fontsize($base-font-size - 2);

		&:hover,
		&:focus {
			outline-width: 2px;
			outline-offset: -4px;
		}

		&:hover {
			outline-style: solid;
		}

		&:focus {
			outline-style: dotted;
		}
	}

	@media screen {
		.wp-block-file__button,
		.wp-block-file__button:focus {
			outline-color: #ffffff;
		}

		.wp-block-file__button {
			color: $base-button-color;
			background: $base-button-background;
			border: $base-button-border;
		}
	}
}
