// Block editor styles
// Block: "core/image"

@mixin block-core-image {
	// Media alignment

	.alignleft,
	.aligncenter,
	.alignright,
	.is-resized {
		display: table;

		figcaption {
			display: table-caption;
			caption-side: bottom;
		}
	}

	.alignnone {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
	}

	.aligncenter {
		margin-left: auto;
		margin-right: auto;
	}

	@include media-breakpoint-up(sm) {
		// Only float if not on an extra small device

		.alignleft {
			float: left;
			margin-right: 1em;
		}

		.alignright {
			float: right;
			margin-left: 1em;
		}
	}
}
