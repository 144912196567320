// Block editor styles
// Block: "core/embed"

@mixin block-core-embed() {
	.wp-block-embed__wrapper {
		position: relative;

		&::before {
			display: block;
			padding-top: 50%;
			content: "";
		}

		iframe {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
		padding-top: 42.85%;
	}

	.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
		padding-top: 50%;
	}

	.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
		padding-top: 56.25%;
	}

	.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
		padding-top: 75%;
	}

	.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
		padding-top: 100%;
	}

	.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
		padding-top: 66.66%;
	}

	.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
		padding-top: 200%;
	}
}
