// Block editor styles
// Block: "core/list"

@mixin block-acf-rss {
	.acf-rss {
		li {
			margin-bottom: 1.5rem;
		}

		.title {

		}

		.date {
			margin: .5rem 0;
		}

		.description {
			margin-bottom: 2rem;

			p {
				margin-bottom: .5rem;
			}
		}
	}
}
